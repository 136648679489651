<template>
  <div class="left-menu">
    <router-link
      :class="curr == item.key ? 'curr' : ''"
      :key="index"
      :to="{name: 'database-config', query: {key: item.key}}"
      @click.native="setConfig"
      class="left-menu-item"
      v-for="(item, index) in keyArr"
    >{{item.name}}</router-link>
    <router-link
      :class="$route.name=='database-cartype' ? 'curr' : ''"
      :to="{name: 'database-cartype', query: {key: 'carType'}}"
      class="left-menu-item"
    >车型说明</router-link>
    <router-link
      :class="$route.name=='database-result' ? 'curr' : ''"
      :to="{name: 'database-result', query: {key: 'Result'}}"
      class="left-menu-item"
    >促进结果</router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      curr: '',
      currTitle: '',
      keyArr: [
        {
          name: '客户类型',
          key: 'customerType'
        },
        {
          name: '区域',
          key: 'customerArea'
        },
        {
          name: '来访时间段',
          key: 'timeInterval'
        },
        {
          name: '来店目的',
          key: 'comeGoal'
        },
        {
          name: '变速箱类型',
          key: 'gearboxType'
        },
        {
          name: '需求排量',
          key: 'displacement'
        },
        {
          name: '外观颜色',
          key: 'outsideColor'
        },
        {
          name: '内饰颜色',
          key: 'insideColor'
        },
        {
          name: '线索渠道',
          key: 'channel'
        },
        {
          name: '营销分析',
          key: 'marketing'
        },
        {
          name: '客户类别',
          key: 'customerType2'
        },
        {
          name: '购车类型',
          key: 'buyTimes'
        },
        {
          name: '客户级别',
          key: 'customerLevel'
        },
        {
          name: '跟踪类型',
          key: 'trackingType'
        },
        // {
        //   name: '竞争品牌',
        //   key: 'competitiveBrand'
        // },
        // {
        //   name: '竞争车型',
        //   key: 'competitiveCar'
        // },
        {
          name: '贷款方式',
          key: 'loansType'
        },
        // {
        //   name: '促进结果',
        //   key: 'Result'
        // },
        {
          name: '战败原因',
          key: 'fallReason'
        },
        {
          name: '意向车型',
          key: 'demandCarType'
        }
      ]
    }
  },
  mounted() {
    this.setConfig()
  },
  methods: {
    setConfig() {
      //判断是否有key
      if (this.$route.query.key == undefined || this.$route.query.key == '') {
        this.$router.push({ query: { key: this.keyArr[0].key } })
      }
      //高亮菜单
      this.curr = this.$route.query.key

      //设置标题
      this.keyArr.find((value, index, arr) => {
        if (value.key == this.curr) {
          this.$emit('setConfig', value.name)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.left-menu {
  border-right: 1px solid #e8e8e8;
  .left-menu-item {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 8px;
    &:hover {
      color: #409eff;
    }
    &.curr {
      background: #e6f7ff;
      font-weight: 500;
      color: #409eff;
      border-right: 3px #409eff solid;
    }
  }
}
</style>